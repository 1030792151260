.CustomButtonTailwind:hover active{
  background-color: #1e40af !important;
 }

.Rewards_hero {
    max-height: 500px;
    height: 330px;
}

/*-------- responsive css ------*/
@media only screen and (max-width: 1024px) {
    .Rewards_hero {
     height: 215px;
    }
}

@media only screen and (max-width: 767px) {
    .Rewards_hero {
      height: 180px;
    }
    .hero__image {
      width: auto;
      height: 100%;
      object-fit: cover;
  }
  .promoCodeDiv_mobile{
    width:130px;
  }
   .Rewards_shop_banner{
    height: 145px;
  }
}

@media only screen and (max-width: 480px) {
    .hero_paragraph{
     width:85%;
    }
    .Rewards_shop_banner{
     height: 100px;
   }
}

@media only screen and (max-width: 380px) {
    .IconHeaderStyle{
      font-size: 20px;
      line-height: 26px;
    }
    .Rewards_hero {
      height: 140px;
    }
    .Rewards_shop_banner{
      height: auto;
    }
    .rewards_shop_hero_img{
      object-position: right;
    }

}

.line-height-desktop {
    line-height: 2.5rem;
  }
  
  @media only screen and (max-width: 768px) {
    .md\:line-height-mobile {
      line-height: normal;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .mt-mobile {
      margin-top: -95px;
    }
    .btn-mt-mobile {
      margin-top: 25px;
    }
    .pt-mobile {
      padding-top: 15px;
    }
    .rewards-coin{
      width: 50%;
    }
    .header-mt{
      margin-top: 1rem;;
    }
    .rewardsCoinIcon-mt{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .request_access_mobile {
      width: 85%;
      height: auto;
      object-fit: cover;
      padding-bottom: 25px;
    } 
    .Request_Access_mobile {
      width: 95%;
      height: auto;
      object-fit: cover;
      margin-top: -10px;
      margin-left: 8px;
      margin-bottom: 10px;
  } 
  .request_sent_text_mobile{
    padding-left: 0.98rem;
  }
  .request_sent_mobile {
    width: 90%;
    height: auto;
    object-fit: cover;
    margin-top: 25px;
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .mobile_mb{
    margin-bottom: 0.95rem;
  }
  .signme_mb{
    margin-top: -0.7rem;
  }
  }
  .Request_Access {
      max-width: 390px;
      width: 90%;
      height: auto;
      object-fit: contain;    
  }
  .request_sent {
    max-width: 390px;
    width: 85%;
    height: 100%;
    object-fit: contain; 
  }
  .p_request_received{
    margin-top: 0.95rem;
  }
  .rewards-total {
    margin-bottom: 15px;
    margin-top: auto;
    padding: 0px 25px;
  }
  .d-none{
    display: none;
  }
  .box-shadow {
    border: 2px solid #31d331;
  }
  .image-magnifier {
    position: relative;
    top: 0;
    right: 0;
    color: #453b3b8c;
    font-size: 1.2rem;
  }
  .cost-points {
    margin-top: 5px;
    margin-left: -25px;
    font-size: 1.0rem;
    line-height: 20px;
    color: rgb(75 85 99 / var(--tw-text-opacity))
  }
  .font-weight-bold {
    font-weight: 900 !important;
  }
  .reward-confirm .reward-image img {
    object-fit: contain;
    max-height: 250px !important;
  }
  
  .skeleton-loader {
    display: inline-block;
    height: 1em;
    width: 100%;
    background: linear-gradient(
      -90deg,
      #f0f0f0 0%,
      #e0e0e0 50%,
      #f0f0f0 100%
    );
    background-size: 200% 100%;
    animation: skeleton-loading 1.5s infinite;
    border-radius: 4px;
    margin-bottom: 8px;
  }
  
  .skeleton-loader--short {
    width: 50%;
  }
  
  .skeleton-loader--medium {
    width: 75%;
  }
  
  .skeleton-loader--image {
    height: 18rem; /* 72px */
    width: 100%;
  }
  
  @keyframes skeleton-loading {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  .button-container {
    transform: translateY(-10px); /* Moves up */
  }